import { createStore } from 'vuex';
import router from "@/router";
import $ from "jquery";
import Torch from "@/Torch";

export default createStore({
    state: {
        auth: {}, // current auth / security data (null if not logged in)
        clientList: {},
        clientListOptions: {}, // version of clientList to be used for drop-downs / multiple selects
        locale: 'en',
        loginStatus: false, // false if not logged in, true if logged in
        loginTimeLeft: 0,
        logoUrl: null,
        messages: [1, 2, 3],
        networkStatus: true,// false if offline, true if online
        pageTitle: '',
        syncInProgress: false,// whether a sync operation is in progress
        user: {} // current user data (null if not logged in)
    },
    mutations: {
        SET_AUTH_DATA(state, data) {
            state.auth = data;
            if (data.token) state.loginStatus = true;
        },
        SET_LOGIN_TIME_LEFT(state, time) { state.loginTimeLeft = time; },
        SET_NETWORK_STATUS(state, status) { state.networkStatus = status; },
        SET_LOGIN_STATUS(state, status) { state.loginStatus = status; },
        SET_PAGE_TITLE(state, title) { state.pageTitle = title; },
        SET_SYNC_STATUS(state, status) { state.syncInProgress = status; },
        SET_USER_DATA(state, data) { state.user = data; },
        UPDATE_CLIENT_LIST(state, data) { state.clientList = data; },
        UPDATE_CLIENT_LIST_OPTIONS(state, data) { state.clientListOptions = data; }
    },
    actions: {
        checkLoginTimeout({ state, commit, dispatch}, vue) {
            //Torch.log('Checking login timeout.');
            // if we are logged in, check to see if our token is nearing expiration
            if ( ! state.loginStatus && state.auth.token) {
                Torch.log('Login status is false but auth token is set: logging out.', true, 'danger');
                dispatch('logout', vue);
            } else if (state.loginStatus && ! state.auth.token) {
                Torch.log('Login status is true but no auth token is set: logging out.', true, 'danger');
                dispatch('logout', vue);
            } else if (state.loginStatus) {
                let tokenExpiry = parseInt(state.auth.expires) * 1000; // expiry unix time in milliseconds
                let currentTime = new Date().getTime(); // current unix time in milliseconds
                let loginTimeLeft = (tokenExpiry - currentTime); // in milliseconds

                //Torch.log("check login timeout: " + (loginTimeLeft/1000/60).toFixed(2) + " minutes left to expiry");
                commit('SET_LOGIN_TIME_LEFT', loginTimeLeft);

                if (loginTimeLeft <= 0) {
                    // expired
                    if (window.navigator.onLine) {
                        Torch.log('Login timeout reached, logging out.', true, 'danger');
                        // todo: auto reauthenticate ?

                        // logout
                        dispatch('logout', vue);
                    } else {
                        // offline, will have to reauthenticate once we are online again
                        Torch.log('Login timeout reached but we are offline...', true, 'danger');
                    }
                } else if (loginTimeLeft < 10 * 1000 * 60) {
                    // less than 10 minutes left - should re-authenticate
                    Torch.log((loginTimeLeft / 1000 / 60).toFixed(0) + ' minutes left before the auth token expires, you should log out and log in again.',
                        true, 'warning');
                } else {
                    // all good
                }
            }
        },
        deleteRecord({ state }, payload) {
            let api_name = payload.model_api_name;
            let id = payload.id;

            let apiUrl = process.env.VUE_APP_API_URL
                + "/" + process.env.VUE_APP_API_VERSION
                + "/torch/entity/" + api_name
                + "/" + id;

            Torch.log("delete record: `" + api_name + "`and id " + id);
            Torch.log(apiUrl);

            return $.ajax({
                url: apiUrl,
                method: "DELETE",
                headers: { 'X-API-Token': state.auth.token, 'X-API-AppId': state.auth.app_id },
                dataType: "json"
            })
                .then(async function (response) {
                    if (response.status === 1) {
                        let data = response.data;
                        Torch.log(api_name + " record deleted from server");

                        return data;
                    } else {
                        // todo: failed - what to do
                        Torch.log('Unable to delete ' + api_name + ' record from server: ' + response.message, true, 'danger');
                    }
                })
                .catch(function (err) {
                    Torch.log("deleteRecord() failed: " + JSON.stringify(err));
                    if (err.status === undefined) {
                        Torch.log('Unable to delete ' + api_name + ' data from server', true, 'danger');
                    } else {
                        Torch.log('Unable to delete ' + api_name + ' data from server: ' + err.status + ' error occurred: ' + err.statusText, true, 'danger');
                    }
                    return status;
                });
        },
        /**
         * get a collection of data from the server via API and return as an array
         *
         *  Sync endpoint
         * https://smokestaging.fieldinvoice.com/0/torch/entity/{tablename}
         *
         *  e.g.
         *
         * https://smokestaging.fieldinvoice.com/0/torch/entity/division
         * https://smokestaging.fieldinvoice.com/0/torch/entity/permission
         * https://smokestaging.fieldinvoice.com/0/torch/entity/group
         *
         *  Method - GET
         *  Requires header - X-API-Token : <token>
         *
         * todo: change API and code to update instead of replace the data based on create and modified dates?
         * todo: verify companyUrl / API url is valid by loading the page?
         *
         * @param commit
         * @param state
         * @param payload Object
         *
         * @return Promise
         */
        getCollection({ state }, payload) {
            let api_name = payload.entityName;
            let offset = (payload.offset === undefined ? 0 : payload.offset);
            let limit = (payload.limit === undefined ? 1000 : payload.limit);
            let sort = (payload.sort === undefined ? [{column: 'id', order: 'desc'}] : payload.sort);

            // generate the base api URL
            let apiUrl = process.env.VUE_APP_API_URL
                + "/" + process.env.VUE_APP_API_VERSION
                + "/torch/entity/" + encodeURIComponent(api_name)
                + "?offset=" + encodeURIComponent(offset)
                + "&limit=" + JSON.stringify(limit)
                + "&sort=" + JSON.stringify(sort);

            // see if the payload contains any other columns to filter then add them to the query string / URL
            let parts = [];
            let reserved = ['entity', 'offset', 'limit', 'sort'];
            for ( let i = 0; i < payload.length; ++i ) {
                if ( ! reserved.includes(payload[i].name)) {
                    parts.push(encodeURIComponent(payload[i].name) + '=' +
                        encodeURIComponent(payload[i].value));
                }
            }
            let queryString = parts.join('&');
            if (queryString) apiUrl += '&' + queryString;

            Torch.log("getting collection for `" + api_name + "` started for " + limit + " records starting at record " + offset + "");
            Torch.log(apiUrl);

            return $.ajax({
                url: apiUrl,
                method: "GET",
                headers: { 'X-API-Token': state.auth.token, 'X-API-AppId': state.auth.app_id },
                dataType: "json"
            }).then(async function (response) {
                if (response.status === -2) {
                    Torch.log('No records returned for ' + api_name + ' collection');
                } else if (response.status < 1) {
                    // todo: failed - what to do
                    Torch.log('Unable to retrieve ' + api_name + ' data from server: ' + response.message, true, 'danger');
                } else {
                    let data = response.data;
                    Torch.log(api_name + " data received from server: " + Object.keys(data).length + " records found.");

                    return data;
                }
            }).catch(function (err) {
                Torch.log("getCollection entity API failed: " + JSON.stringify(err));
                if (err.status === undefined) {
                    Torch.log('Unable to retrieve ' + api_name + ' data from server', true, 'danger');
                } else {
                    Torch.log('Unable to retrieve ' + api_name + ' data from server: ' + err.status + ' error occurred: ' + err.statusText, true, 'danger');
                }
                return false;
            });
        },
        getRecord({ state }, payload) {
            let api_name = payload.model_api_name;
            let id = payload.id;
            let loadEager = payload.loadEager;

            let apiUrl = process.env.VUE_APP_API_URL
            + "/" + process.env.VUE_APP_API_VERSION
            + "/torch/entity/" + api_name
            + "/" + id;

            if (loadEager) apiUrl += '?loadEager=1';

            Torch.log("getting model for `" + api_name + "`and id " + id);
            Torch.log(apiUrl);

            return $.ajax({
                url: apiUrl,
                method: "GET",
                headers: { 'X-API-Token': state.auth.token, 'X-API-AppId': state.auth.app_id },
                dataType: "json"
            })
            .then(async function (response) {
                if (response.status === -2) {
                    Torch.log('No record returned for ' + api_name);
                } else if (response.status < 1) {
                    // todo: failed - what to do
                    Torch.log('Unable to retrieve ' + api_name + ' record from server: ' + response.message, true, 'danger');
                } else {
                    let data = response.data;
                    Torch.log(api_name + " record received from server");

                    return data;
                }
            })
            .catch(function (err) {
                Torch.log("Table API failed: " + JSON.stringify(err));
                if (err.status === undefined) {
                    Torch.log('Unable to retrieve ' + api_name + ' data from server', true, 'danger');
                } else {
                    Torch.log('Unable to retrieve ' + api_name + ' data from server: ' + err.status + ' error occurred: ' + err.statusText, true, 'danger');
                }
                return status;
            });
        },
        /**
        * Login a user.
        *
        * @param state
        * @param loginVue - the login VUE object
        */
        async login ( { state }, loginVue ) {
            Torch.log(state.loginStatus); // todo: don't need this, but can't figure out how to not pass state...

            if ( ! loginVue.username) {
                loginVue.showAlert('Please enter a valid username');
                return false;
            }

            if ( ! loginVue.password) {
                loginVue.showAlert('Please enter a valid password.');
                return false;
            }

            // hide the form and display the circular waiting indicator
            loginVue.progressDisplay = true;

            let username = loginVue.username;
            let password = loginVue.password;
            let apiUrl = process.env.VUE_APP_API_URL;
            let apiVersion = process.env.VUE_APP_API_VERSION;

            loginVue.progress = 0;

            Torch.log( "Login attempt for username: " + loginVue.username + " using " + apiUrl + "/login");

            // clear the password field
            loginVue.password = '';
            loginVue.tvid = null;

            loginVue.progress += 5;

            // first try to login
            if (navigator.onLine) {
                Torch.log('Attempting online login');

                let appId = localStorage.getItem('torch_app_id');

                // if not logged in and online, try to log in
                await $.ajax({
                    url: apiUrl + '/' + apiVersion + '/torch/login',
                    method: "POST",
                    data: {
                        user_agent: navigator.userAgent,
                        username: username,
                        password: password
                    },
                    headers: { 'X-API-AppId': appId },
                    dataType: "json"
                })
                // verify login status and set up local parameters, then get person table
                .then(async function (response) {
                    Torch.log('Attempting online login - step 1');
                    Torch.log(response);
                    loginVue.progress += 15;
                    if (response.status === 1) {
                        // remember app id
                        let appId = response.data['appId'];
                        localStorage.setItem("torch_app_id", appId);

                        // set return data and prompt for code
                        loginVue.appId = appId;
                        loginVue.tvid = response.data['tvid']; // this will initiate code prompt on login page if not zero
                        loginVue.userId = response.data['id'];
                        loginVue.firstName = response.data['first_name'];
                        loginVue.lastName = response.data['last_name'];
                    } else {
                        loginVue.progressDisplay = false;
                        throw('login failed: ' + response.message);
                    }
                })
                .catch(function (err) {
                    loginVue.progressDisplay = false;

                    let message = "";
                    Torch.log(err);
                    if (err.status === 401 || err.status === 500) {
                        message = err.responseJSON.message;
                    } else if (typeof err === 'string' || err instanceof String) {
                        message = err;
                    } else {
                        message = "unknown error";
                    }
                    loginVue.showAlert(message, 'danger');

                    return message;
                });
            } else {
                // offline, cannot log in
                Torch.log("FireApp.login offline, cannot login");
                loginVue.progressDisplay = false;
            }
        },
        /**
        * Logout the current user, but hold on to some data in order to initiate a potential offline login.
        * todo: Currently written to except the calling Vue object as vm - so it is tightly coupled but could/should be uncoupled
        * @param state
        * @param commit
        * @param vm
        */
        logout ( { state, commit } ) {
          // close modal dialogs (if in use and open)
          // the vues have listeners to clean up properly when the modal is hidden
          //if (vm && vm.$bvModal) vm.$bvModal.hide('global_modal'); // todo: this does not seem to be working
          let modals = document.getElementsByClassName('modal');
          for (let i=0, len=modals.length|0; i<len; i=i+1|0) {
              modals[i].dispose();
          }

          // clear user data
          // todo: still working on best way to handle offline login
          //context.commit('SET_AUTH_DATA', null);
          //context.commit('SET_USER_DATA', null);
          let authData = state.auth;
          authData.token = null;
          commit('SET_AUTH_DATA', authData);

          commit('SET_LOGIN_STATUS', false);

          // open login screen
          Torch.log('redirect to logout');
          router.push('/logout').catch(err => { Torch.log(err); });
        },
        /**
         * send a record to Smoke (put or post depending on if the id is > 0)
         *
         * @param commit
         * @param state
         * @param payload
         * @returns {*}
         */
        saveEntity({ state }, payload) {
            let apiName = payload.apiEntityName;
            let id = (payload.data.id !== undefined ? parseInt(payload.data.id) : 0);
            let data = payload.data;

            let apiUrl = process.env.VUE_APP_API_URL
                + "/" + process.env.VUE_APP_API_VERSION
                + "/torch/entity/" + apiName;

            // for edit case, add id to url
            if (id > 0) apiUrl += "/" + id;

            Torch.log("putting entity for api name `" + apiName + "` to the server");
            Torch.log(apiUrl);

            return $.ajax({
                url: apiUrl,
                method: (id > 0) ? "PUT" : "POST",
                headers: { 'X-API-Token': state.auth.token, 'X-API-AppId': state.auth.app_id },
                cache: false,
                data: data,
                dataType: "json"
            }).then(function (response) {
                if (response.status === 1) {
                    let poId = response.data['id'];
                    Torch.log('The ' + apiName + ' record was uploaded to the server with new id: ' + poId,
                        true, 'success');

                    return poId; //v.delete();
                } else {
                    Torch.log('Error in server upload (ajax / server error): ' + response.message,
                        true, 'danger');
                    throw(response.message);
                }
            })
                .catch(function (e) {
                    Torch.log(e);
                    Torch.log('ERROR: server upload failed.', true, 'danger');
                    throw('Upload failed');
                });
        },
        setAuthData ({ commit }, data) {
            commit('SET_AUTH_DATA', data);
            if (data.token && data.expires) {
                Torch.log('token expired?');
            }
            if ( ! data) {
                localStorage.removeItem('token');
            }
        },
        setLocale({ state, commit }, locale) {
            if (locale === undefined) locale = state.locale;
            let language = locale.substr(0,2);
            Torch.console('switch locale to ' + locale + ', language ' + language);

            commit('SET_LOCALE', locale);

            // remember for next time
            localStorage.setItem('locale', locale);

            // set the language
            $.i18n().locale = language;

            // reload page translations
            $('body').i18n();
        },
        setLoginStatus ({commit }, status) {
            commit('SET_LOGIN_STATUS', status);
        },
        setPageTitle ({ commit }, title) {
            commit('SET_PAGE_TITLE', title);
        },
        setUserData ({ commit }, data) {
            commit('SET_USER_DATA', data);
        },
        setNetworkStatus(context) {
          let status = window.navigator.onLine;

          // if the status changes, notify the user
          if (context.state.networkStatus !== status) {
              // commit the change to the state
              context.commit('SET_NETWORK_STATUS', status);

              // act on network change
              if (status) {
                  // just went online
                  $(".online").show();
                  $(".offline").hide();
                  Torch.log('Your network appears to be available again, switching to online mode.',
                      true,'success');
                  // todo: sync? or wait until we have been online for a min safe time (like 1 minute?)

              } else {
                  // just went offline
                  $(".online").hide();
                  $(".offline").show();
                  Torch.log('Your network appears to be unavailable, switching to offline mode.',
                      true,'warning');
              }
          }
        },
        /**
         * Grab the latest global company data from the smoke database and update the store state.
         * @param commit
         */
        updateClientList({commit, dispatch}) {
            return dispatch('getCollection', {
                entityName: 'global-company',
                offset: 0,
                limit: 500,
                sort: [{column: 'name', order: 'desc'}],
                loadEager: 1
            }).then(function(data) {
                //console.log(data);
                commit('UPDATE_CLIENT_LIST', data);

                // generate basic option list
                let list = {};
                Object.values(data).forEach(row => {
                    // only use non-archived companies
                    if (row.global_company_status_id !== '6') {
                        list[row.id] = {
                            id: row.id,
                            name: row.name
                        };
                    }
                });
                // sort alphabetical by name
                /*
                let sortedOptionList = list.sort(function(a,b) {
                    let nameA = a.name.toUpperCase();
                    let nameB = b.name.toUpperCase();
                    if (nameA < nameB) return -1;
                    if (nameA > nameB) return 1;
                    return 0;
                });
                 */

                commit('UPDATE_CLIENT_LIST_OPTIONS', list);

                return data;
            });
        },
        async verifySmsCode({commit, dispatch}, loginVue ) {
            if ( ! loginVue.username) {
                loginVue.showAlert('Please enter a valid username');
                return false;
            }

            if ( ! loginVue.code) {
                loginVue.showAlert('Please enter a valid code');
                return false;
            }

            let apiUrl = process.env.VUE_APP_API_URL;
            let apiVersion = process.env.VUE_APP_API_VERSION;

            Torch.log('Attempting online verification - step 1');
            Torch.log( "Verification attempt for username: " + loginVue.username + " using " + apiUrl + "/login");

            loginVue.progress += 5;

            // first try to login
            if (navigator.onLine) {
                Torch.log('Attempting online verification');

                let appId = localStorage.getItem('torch_app_id');

                // if not logged in and online, try to log in
                await $.ajax({
                    url: apiUrl + '/' + apiVersion + '/torch/confirm',
                    method: "POST",
                    data: {
                        username: loginVue.username,
                        code: loginVue.code,
                        tvid: loginVue.tvid
                    },
                    headers: { 'X-API-AppId': appId },
                    dataType: "json"
                })
                // verify login status and set up local parameters, then get person table
                .then(async function (response) {
                    Torch.log('Attempting online login - step 3');
                    loginVue.progress += 15;
                    if (response.status === 1) {
                        // remember app id
                        let appId = response.data['appId'];
                        localStorage.setItem("torch_app_id", appId);

                        let token = response.data["token"];
                        let expires = response.data["expires"];

                        let ts = Math.round((new Date()).getTime() / 1000);
                        let expireTimeInHours = (expires - ts) / 60 / 60;
                        Torch.log('Server authentication succeeded, acquired token valid for ' + expireTimeInHours.toFixed(2) + ' hours.', false);

                        commit('SET_AUTH_DATA', {
                            app_id: appId,
                            token: token, // todo: maybe shouldn't be here...
                            expires: expires
                        });

                        dispatch('setUserData', {
                            id: loginVue.userId,
                            username: response.data["username"],
                            proper_name: loginVue.firstName + " " + loginVue.lastName
                        });

                        // update session
                        sessionStorage.setItem("login_time", Date.now());
                        sessionStorage.setItem("last_access_time", Date.now());

                        dispatch('setLoginStatus', true);
                    } else if (response.status === -20) {
                        // tvid may have expired or already been verified
                        loginVue.tvid = null;
                        loginVue.code = null;
                        throw('login failed: ' + response.message);
                    } else {
                        loginVue.code = null;
                        throw('login failed: ' + response.message);
                    }
                })
                .catch(function (err) {
                    loginVue.progressDisplay = false;
                    let message = "";
                    Torch.log(err);
                    if (err.status === 401) {
                        message = err.responseJSON.message;
                    } else if (typeof err === 'string' || err instanceof String) {
                        message = err;
                    } else {
                        message = "unknown error";
                    }
                    loginVue.showAlert(message, 'danger');
                    loginVue.code = null;

                    return message;
                });
            } else {
                // offline, cannot log in
                Torch.log("FireApp.login offline, cannot login");
                loginVue.progressDisplay = false;
                loginVue.code = null;
            }
        }
    },
    modules: {
    }
})
