//import Vue from "vue";
import dayjs from "dayjs";

export default class Torch {
    static SESSION_TIMEOUT_IN_SECONDS = 60 * 60;
    static SESSION_TIMEOUT_CHECK_INTERVAL_IN_SECONDS = 60;
    static NETWORK_CHECK_INTERVAL_IN_SECONDS = 5;

    // these are duplicated in service-worker.js
    // Update cache names any time any of the cached files change.
    static CACHE_NAME = "static-cache";
    static DATA_CACHE_NAME = "data-cache";

    static API_PATH = "/api/public";

    constructor() {
    }

    /**
     * Initialize the app, called from main.js
     */
    initialize() {
        Torch.log('App initialization');
    }

    static console(msg) {
        if (window.console) console.log(msg);
    }

    /**
     * Convert a JS Date object to a MySQL date time string like YYYY-MM-DD HH:MM:SS.
     * toISOString returns 2011-10-05T14:48:00.000Z
     * @param jsDate        a javascript Date() object, defaults to today
     * @returns {string}    a MySQL date time string, e.g. "2010-06-09 13:12:01"
     */
    static convertJsDateToMysqlDate(jsDate) {
        if (jsDate === undefined) {
            jsDate = new Date();
        }
        return jsDate.toISOString().substr(0,10); //slice(0, 19).replace('T', ' ');
    }

    /**
     * Take a MySQL data string YYYY-MM-DD HH:MM:SS and return a javascript date object for it.
     * @param dateString  // e.g. "2010-06-09 13:12:01"
     * @returns {Date}
     */
    static convertMysqlDateToJsDate(dateString) {
        // Split timestamp into [ Y, M, D, h, m, s ]
        let t = dateString.split(/[- :]/);

        return new Date(Date.UTC(t[0], t[1]-1, t[2], t[3], t[4], t[5]));
    }

    /**
     * Force the service worker to initiate a software update.
     */
    static forceSWUpdate = function() {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.getRegistrations().then(function(registrations) {
                for (let registration of registrations) {
                    registration.update();
                }
            });
        }
    };

    static formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    /**
     * Return a currency formatted based on the locale.
     * @param amount
     * @param showZero
     * @returns {string}
     */
    static formatCurrency(amount, showZero) {
        let preparedNumber = (isNaN(amount) ? 0 : parseFloat(amount));

        if (preparedNumber || showZero) {
            return new Intl.NumberFormat('en-CA', {
                style: "currency",
                currency: "CAD", // CAD, USD
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            }).format(preparedNumber);
        } else {
            return '';
        }

    }

    /**
     * Return a localized date string - will use current date if no string passed.
     * @param dateString (optional)
     * @returns {string}
     */
    static formatDate(dateString) {
        let date = null;
        if (dateString === undefined) {
            date = new Date();
        } else {
            date = new Date(dateString);
        }

        return new Intl.DateTimeFormat(parent.locale).format(date);
    }

    /**
     * Return the current date as Jul 12, 2020 (as appropriate based on user locale)
     * @returns {string}
     */
    static getFormattedDate() {
        return new Date().toLocaleDateString(undefined, {
            day : 'numeric',
            month : 'short',
            year : 'numeric'
        }); // undefined tells the browser to use the user's locale
    }

    /**
     * return a formatted local MySQL date time string
     * todo: fix this, not working, not ideal
     *
     * @returns {string}
     */
    static getDateTime() {
        return dayjs().format();
        /*
        let localDateTime = new Date()
            //.toLocaleString("en-US", {timeZone: "America/Edmonton"})
            .toISOString()
            .slice(0, 19).replace('T', ' ');
    
        return localDateTime
            //.toISOString()
            //.replace(/T/, " ")
            //.replace(/\..+/, "");
         */
    }

    /**
     * Create a hash of the given string.  Used to hash offline credentials.
     *
     * @param target_string
     * @returns {number}
     */
    static hashCode(target_string) {
        let hash = 0,
            i,
            chr;
        if (target_string.length === 0) return hash;
        for (i = 0; i < target_string.length; i++) {
            chr = target_string.charCodeAt(i);
            hash = (hash << 5) - hash + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    }

    /**
     * Determine if the user is logged in.
     *
     */
    static isLoggedIn() {
        return localStorage.getItem('auth_token');
    }

    /**
     * generate a log entry and optionally display as a toast (default false), display in console (default true) and
     * create a log entry in the spark indexdb log table (default true).
     *
     * @param logEntry           - the text update
     * @param updateStatusFlag   - update status, defaults to false
     * @param statusTextVariant  - optional bootstrap text variant (like success, warning, danger, etc.)
     * @param updateConsoleFlag  - update console, defaults to true in non-production sites
     */
    static log(logEntry, updateStatusFlag, statusTextVariant, updateConsoleFlag) {
        let logDate = Torch.getDateTime();

        // update the status (toast)
        if (updateStatusFlag !== undefined && updateStatusFlag) {
            if ( ! statusTextVariant) statusTextVariant = 'body';
            Torch.updateStatus(logDate + ": " + logEntry, 'Status', statusTextVariant);
        }

        // update the console (as long as this is NOT production and update_console_flag is not set or is set to true
        //if (process.env.NODE_ENV !== 'production' && (update_console_flag === undefined || update_console_flag)) {
        if (updateConsoleFlag === undefined || updateConsoleFlag) {
            Torch.console(logDate + ": " + logEntry);
        }
    }

    /**
     * Execute a full download sync from the FIRE server.
     * @returns {Promise<unknown[]>}
     */
    /*
    static fullSyncDown() {
      let promiseList = [];
  
      for (let i=0; i < this.SERVER_OBJECT_STORE_LIST.length; i++) {
        promiseList.push(Torch.getData(this.SERVER_OBJECT_STORE_LIST[i]));
      }
      return Promise.all(promiseList).then(function() {
        localStorage.setItem('last_full_sync', Date.now().toString());
      });
    }*/
    /**
     * Resize an image using temporary image and canvas elements in the DOM.
     *
     * @param tempImage
     * @param maxWidth
     * @param maxHeight
     */
    static resizeImage(tempImage, maxWidth, maxHeight) {

        console.log('resize image called, original: ' + tempImage.width + 'x' + tempImage.height + ' to ' + maxWidth + 'x' + maxHeight);
        //console.log(`w: ${tempImage.width} | h: ${tempImage.height}`);

        // write the image to a new temporary canvas element (not displayed)
        let tempCanvas = document.createElement("canvas");
        // maximize the size of the image in the canvas (based on orientation)
        if (tempImage.width > tempImage.height) {
            // landscape
            tempCanvas.width = maxWidth;
            tempCanvas.height = maxWidth * tempImage.height/tempImage.width;
        } else {
            // portrait
            tempCanvas.width = maxHeight * tempImage.width/tempImage.height;
            tempCanvas.height = maxHeight;
        }

        tempCanvas.getContext("2d")
            .drawImage(tempImage, 0, 0, tempCanvas.width, tempCanvas.height);

        // convert the canvas to a new png image url
        let pngVersionUrl = tempCanvas.toDataURL("image/png");

        // destroy the temporary elements
        tempImage.remove();
        tempCanvas.remove();

        // return the resized image url
        return pngVersionUrl;
    }

    /**
     * add a status update 'toast' with the given title, message and variant
     * if the variant is 'danger' the toast does not auto-hide, otherwise the toast auto hides in 2.5 seconds
     * @param text     - the body of the toast
     * @param title    - the title of the toast
     * @param variant  - bootstrap variant like 'danger' or 'warning' or 'success', etc.
     */
    static updateStatus(text, title, variant) {
        //const vm = new Vue();

        if (title === undefined) title = 'Status';
        if (variant === undefined) variant = 'secondary';

        //let toastClass = Vue.extend(Toast);
        //let toast = new toastClass({ propsData: { text: text, title: title, variant: variant } });
        //toast.$mount();

        //$('#toast-container').append(toast.$el);

        /* todo: replace this by creating a Toast.vue component and insert it in to #toast-container
    
    
        // see https://bootstrap-vue.org/docs/components/toast
        vm.$bvToast.toast(text, {
          title: title,
          variant: variant,
          autoHideDelay: (variant === 'danger' ? 10000 : 2500),
          //noAutoHide: (variant === 'danger'),
          appendToast: true
        })
        */
    }


    /**
     * Send the current record to FIRE Server API
     * todo: move all API code to a separate class
     * todo: handle update case, right now it is just new local record case
     * @returns {Promise<void>}
     */
/*
    static uploadRecord(tableName, payload) {
        let companyUrl = localStorage.getItem('auth_company');
        let apiUrl = "https://" + companyUrl + Torch.API_PATH + "/save/" + tableName;

        Torch.log('Attempt to send a ' + tableName + ' record to the server');

        return $.ajax({
            url: apiUrl,
            method: (id) ? "PUT" : "POST",
            headers: { 'X-API-Token': localStorage.getItem('auth_token'), 'X-API-AppId': sessionStorage.getItem('app_id') },
            cache: false,
            data: payload,
            dataType: 'json'
        })
            .then(function(data) {
                //console.log(data);

                if (data['status'] === 1) {
                    Torch.log('The record was synced to the server.', true, 'success');
                    return v.delete();
                } else {
                    Torch.log('error in server download (ajax / server error): ' + data['message']);
                    throw(data['message']);
                }
            })
            .then(function() {
                // sync the server data for this table back to Spark (including the new record)
                return Torch.getData(v.apiName).then(function() {
                    // refresh the any associated tables as well
                    if (v._hasMany.length > 0) {
                        v._hasMany.forEach(function(table) {
                            Torch.getData(table);
                        });
                    }
                });
            })
            .catch(function(err) {
                Torch.log('record upload failed: ' + JSON.stringify(err));

                if (err.status === undefined) {
                    Torch.log('unknown error in server upload - possibly in db code');
                } else {
                    Torch.log('unable to upload the data, ' + err.status + ' error occurred: ' + err.statusText);
                }
                throw(err);
            });
    }
*/
}
