<template>
    <header v-if="loginStatus" class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
        <img src="/images/fire_logo_57x57.png">
        <h1 v-if="false && pageTitle !== ''" class="display-5 text-light">{{ pageTitle }}</h1>
        <a class="navbar-brand" href="#">{{ title }} v{{ version }}</a>
        <button class="navbar-toggler position-absolute d-md-none collapsed"
                type="button"
                data-toggle="collapse"
                data-target="#sidebarMenu"
                aria-controls="sidebarMenu"
                aria-expanded="false"
                aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <!--
        <input class="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-light ms-2 me-2" id="global-save-button">Save</button>
        <button class="btn btn-outline-light me-2" id="global-cancel-button">Cancel</button>
        -->
    </header>

    <div class="container-fluid">
        <div class="row">
            <nav v-if="loginStatus" id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
                <div class="position-sticky pt-3">
                    <ul class="nav flex-column">
                        <li class="nav-item">
                            <router-link to="/" class="nav-link active" aria-current="page">dashboard</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/client" class="nav-link active">client</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/sales" class="nav-link active">sales</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/torch" class="nav-link active">torch</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/smoke" class="nav-link active">smoke</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/spark" class="nav-link active">spark</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/fire" class="nav-link active">fire</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/test" class="nav-link active">test</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/help" class="nav-link active">help</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/integration" class="nav-link active">integration</router-link>
                        </li>
                    </ul>

                    <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                        <span>reports</span>
                        <a class="link-secondary" href="#" aria-label="Add a new report">
                            <span data-feather="plus-circle"></span>
                        </a>
                    </h6>
                    <ul class="nav flex-column mb-2">
                        <li class="nav-item">
                            <a class="nav-link" href="#">cash flow</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">social engagement</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">customer activity</a>
                        </li>
                    </ul>

                    <a class="nav-link" href="#" @click="logout">Sign out</a>
                </div>
            </nav>

            <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                <router-view/>
            </main>
        </div>
    </div>
</template>

<script>
import $ from "jquery";
import { mapActions } from "vuex";
import { mapState } from 'vuex';
const {version} = require('../package.json');

export default {
  name: "App",
      components: {
          //TheHeader: () => import("./components/TheHeader.vue"),
          //TheFooter: () => import("./components/TheFooter.vue")
      },
      computed: {
        version() {
            return version;
        },
        ...mapState([
            'loginStatus',
            'pageTitle',
            'user'
        ])
      },
      created() {
        document.addEventListener(
            'swUpdated', this.showRefreshUI, { once: true }
        );
        navigator.serviceWorker.addEventListener( 'controllerchange', () => {
              if (this.refreshing) return;
              this.refreshing = true;
              window.location.reload();
            }
        );
      },
      data: function() {
          return {
              refreshing: false,
              registration: null,
              title: process.env.VUE_APP_TITLE,
              updateExists: false
          }
      },
      methods: {
          refreshApp () {
              this.updateExists = false;
              if ( ! this.registration ||  ! this.registration.waiting) { return; }
              this.registration.waiting.postMessage('skipWaiting');
          },
          showRefreshUI (e) {
              this.registration = e.detail;
              this.updateExists = true;
          },
          ...mapActions([ // map store methods to this: `this.setAuthData(data)` to `this.$store.dispatch('setAuthData', data)`, etc.
              'setNetworkStatus',
              'checkLoginTimeout'
          ]),
          logout() {
              this.$store.dispatch('logout', this);
          }
      },
      mounted: function() {
          let v = this;

          this.$nextTick(function() {
              // Code that will run only after the entire view has been rendered
              $("body").click(function() {
                  sessionStorage.setItem("last_access_time", Date.now());
              });

              // avoid lingering borders on selected links
              $('a').click(function() { this.blur(); });

              // update the network status every 10 seconds
              v.setNetworkStatus();
              setInterval(function() {
                  v.setNetworkStatus();
              }, 10 * 1000);

              // check for the login timeout and seq id availability every minute
              // todo: create list of routes that do not require checking - or move to router?

              v.checkLoginTimeout(v);
              setInterval(function() {
                  v.checkLoginTimeout(v);
              }, 60 * 1000);

          });
      }
  };
</script>

<style>
    #nav {
      padding: 30px;
    }
    #nav a {
      font-weight: bold;
      color: #2c3e50;
    }
    #nav a.router-link-exact-active {
      color: #42b983;
    }

    .bd-placeholder-img {
        font-size: 1.125rem;
        text-anchor: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
    }

    @media (min-width: 768px) {
        .bd-placeholder-img-lg {
            font-size: 3.5rem;
        }
    }


    body {
        font-size: .875rem;
        overflow-y: scroll;
    }

    .feather {
        width: 16px;
        height: 16px;
        vertical-align: text-bottom;
    }

    /*
     * Sidebar
     */

    .sidebar {
        position: fixed;
        top: 0;
        /* rtl:raw:
        right: 0;
        */
        bottom: 0;
        /* rtl:remove */
        left: 0;
        z-index: 100; /* Behind the navbar */
        padding: 48px 0 0; /* Height of navbar */
        box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    }

    @media (max-width: 767.98px) {
        .sidebar {
            top: 5rem;
        }
    }

    .sidebar-sticky {
        position: relative;
        top: 0;
        height: calc(100vh - 48px);
        padding-top: .5rem;
        overflow-x: hidden;
        overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    }

    .sidebar .nav-link {
        font-weight: 500;
        color: #333;
    }

    .sidebar .nav-link .feather {
        margin-right: 4px;
        color: #727272;
    }

    .sidebar .nav-link.active {
        color: #007bff;
    }

    .sidebar .nav-link:hover .feather,
    .sidebar .nav-link.active .feather {
        color: inherit;
    }

    .sidebar-heading {
        font-size: .75rem;
        text-transform: uppercase;
    }

    /*
     * Navbar
     */

    .navbar-brand {
        padding-top: .75rem;
        padding-bottom: .75rem;
        font-size: 1rem;
        background-color: rgba(0, 0, 0, .25);
        box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
    }

    .navbar .navbar-toggler {
        top: .25rem;
        right: 1rem;
    }

    .navbar .form-control {
        padding: .75rem 1rem;
        border-width: 0;
        border-radius: 0;
    }

    .form-control-dark {
        color: #fff;
        background-color: rgba(255, 255, 255, .1);
        border-color: rgba(255, 255, 255, .1);
    }

    .form-control-dark:focus {
        border-color: transparent;
        box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
    }
</style>
