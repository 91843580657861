import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index.js';

const routes = [
  {
    path: "/",
    name: "login",
    component: () => import(/* webpackChunkName: "torch" */ "@/views/Login.vue"),
    meta: { public: true }
  },
  {
    path: "/reset",
    name: "reset",
    component: () => import(/* webpackChunkName: "torch" */ "@/views/Reset.vue"),
    meta: { public: true }
  },
  { path: '/logout', redirect: { name: 'login' }},
  {
    path: '/',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "torch" */ "@/views/Dashboard.vue"),
  },
  {
    path: '/client',
    name: 'client',
    component: () => import(/* webpackChunkName: "client" */ '@/views/Client.vue')
  },
  {
    path: "/client/:id",
    name: "client_edit",
    component: () => import(/* webpackChunkName: "client" */ "@/views/Client/Edit.vue")
  },
  {
    path: '/sales',
    name: 'sales',
    component: () => import(/* webpackChunkName: "sales" */ '@/views/Sales.vue')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "torch" */ '@/views/Test.vue')
  },
  {
    path: '/torch',
    name: 'torch',
    component: () => import(/* webpackChunkName: "torch" */ '@/views/Torch.vue')
  },
  {
    path: "/torch/user/:id",
    name: "torch_user_edit",
    component: () => import(/* webpackChunkName: "torch" */ "@/views/Torch/UserEdit.vue")
  },
  {
    path: '/smoke',
    name: 'smoke',
    component: () => import(/* webpackChunkName: "smoke" */ '@/views/Smoke.vue')
  },
  {
    path: '/spark',
    name: 'spark',
    component: () => import(/* webpackChunkName: "spark" */ '@/views/Spark.vue')
  },
  {
    path: '/fire',
    name: 'fire',
    component: () => import(/* webpackChunkName: "fire" */ '@/views/Fire.vue')
  },
  {
    path: '/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "help" */ '@/views/Help.vue')
  },
  {
    path: '/integration',
    name: 'integration',
    component: () => import(/* webpackChunkName: "integration" */ '@/views/Integration.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// check permissions
router.beforeEach((to, from, next) => {
  // basic route permission check
  if (to.meta.public) {
    // public route - you may proceed
    next();
  } else if ( ! store.state.auth || ! store.state.auth.token) {
    // user is not logged in and this is a secure route -> redirect to login page
    next({ name: "login" });
  } else {
    // good to go, carry on
    store.dispatch('setPageTitle', to.name.toUpperCase());
    next();
  }
});

export default router
