import { createApp } from 'vue';
import App from './App.vue';
import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap';
import router from './router';
import store from './store';

// set up icons used from font awesome
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft, faArrowRight, faCheckCircle, faCircle, faCog, faExclamationTriangle, faPlus, faSignOut, faSpinner, faSync, faUndo,
    faUserCowboy, faWifi, faWifiSlash, faTrashAlt, faFire } from '@fortawesome/pro-light-svg-icons';

library.add(faArrowLeft, faArrowRight, faCheckCircle, faCircle, faCog, faExclamationTriangle, faPlus, faSignOut, faSpinner, faSync, faUndo,
    faUserCowboy, faWifi, faWifiSlash, faTrashAlt, faFire);

const app = createApp(App)
    .use(store)
    .use(router)
    .component('font-awesome-layers', FontAwesomeLayers)
    .component('font-awesome-icon', FontAwesomeIcon);

app.config.devtools = true;
app.mount('#app');